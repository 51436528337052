/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState,useRef,useEffect } from 'react';
//import { useAppContext } from "../libs/contextLib";
import withWrapper from '../components/wrapper.js';
import { navigate } from "gatsby";
import checkmark from '../images/checkmark.svg';
import Amplify, { Auth } from "aws-amplify";
import awsconfig from '../aws-exports';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

Amplify.configure(awsconfig);


function _inner (props) {
//  const { userHasAuthenticated } = useAppContext();
  const codeRef = useRef(null);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [confirmed, setConfirmed] = useState("");
  function parseQuery(queryString) {
      var query = {};
      var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
      for (var i = 0; i < pairs.length; i++) {
          var pair = pairs[i].split('=');
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
      return query;
  }
  useEffect(() => {
    let qu = parseQuery(props.location.search);
    let email=qu.email;
    let code=qu.code;
    setEmail(email);
    setCode(email);
    if(email && code) {
      confirmation(email,code);
    }
  },[props.location]);

  function validateForm() {
    let val=code.length>0;
    return val;
  }

  function setIsLoading() {
      
  }
  async function confirmation(email,code) {
    console.log("email");
    console.log(email);
    console.log("code");
    console.log(code);
    setIsLoading(true);
    try {
      console.log("inner");
      await Auth.confirmSignUp(email, code);
      if(window.location.hostname=="www.valtrace.com") {
        ReactGA.event({
          category: 'Signup',
          action: 'Email Verification'
        });
      }
      setConfirmed(true);
      if(props.location.state && props.location.state.password) {
        await Auth.signIn(email, props.location.state.password);

//      userHasAuthenticated(true);
        navigate("/?newCompany");
      } else {
        navigate("/signin?confirmed=true");
          //need to navigate to a login page
      }
    } catch (e) {
      if(e.message=="User cannot be confirmed. Current status is CONFIRMED") {
        navigate("/signin?confirmed=true");
      }
      console.log("cognito error response");
      console.log(e);
      props.setText("Failure: "+e.message);
      setIsLoading(false);
    }
  }
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    
    confirmation(email,code);
  }
  
  let topstyle={position: 'relative',
    width: '100%',
  };
  
  let innerStyle={
    
  };
  
  return (
    <div className="LoginForm" style={topstyle} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Confirmation</title>
        <link rel="canonical" href="https://www.valtrace.com/confirmation/" />
        <html lang="en" />
      </Helmet>

      <div style={innerStyle} >
        <style>{`
          .LoginForm input {
            border-radius: 2px;
            border: 1px solid #aaa;
            padding: 2px;
          }
        `}
        </style>
        {confirmed ?
        <div>Thank you for confirming your email address.</div>
        :        
          <form onSubmit={handleConfirmationSubmit}>
            <div style={{padding:'5px 0px', textAlign:'center', fontSize:'1.2em'}}>Check Your Email</div>
            <div style={{padding:'5px 0px', textAlign:'center'}} className="form-group">
                <input placeholder="Verification Code" autofocus style={{marginLeft:'15px',width:'15em'}} type="text" className="form-control Hip" value={code} onChange={e => setCode(e.target.value)} />
            </div>
            <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
              <button type="submit" className='btn' disabled={!validateForm()} >Confirm Email</button>
            </div>
          </form>
        }
      </div>
    </div>
  );
}

export default withWrapper(_inner);